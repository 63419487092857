<template>
  <div class="contacts-metrics" style="min-height: 680px">
    <div class="charts">
      <b-card no-body>
        <div v-if="data.length > 0">
          <h3>Statistic by Channels:</h3>
          <ag-charts-vue
            :options="options">
          </ag-charts-vue>
        </div>
        <b-skeleton height="100%" v-else></b-skeleton>
      </b-card>
    </div>
  </div>
</template>

<script>
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import { AgChartsVue } from 'ag-charts-vue'
// API Init
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'

export default {
  name: 'lead-center-charts-contacts',
  data() {
    return {
      totalLeadsCount: 0,
      options: null,
      data: []
    }
  },
  props: ['request'],
  components: {
    AgChartsVue
  },
  watch: {
    data() {
      this.totalLeadsCount = 0
      this.setOptions()
    },
    request() {
      this.init(this.request)
    }
  },
  created() {
    this.setOptions()
  },
  mounted() {
    this.init(this.request)
  },
  beforeDestroy() {
    this.$emitter.off('header_addons_changed')
    this.$emitter.off('header_reset_filters')
  },
  methods: {
    ...mapGetters([
      'getLeadCenterDate',
      'getSelectedPlatform'
    ]),
    setOptions() {
      this.options = {
        height: 600,
        data: [],
        series: []
      }
      if (this.data) {
        const channels = []
        for (const d of this.data) {
          channels.push(...Object.keys(d.channels))
        }
        const uniqueChannels = channels.filter((value, index) => {
          return channels.indexOf(value) === index
        })
        this.options = {
          height: 600,
          data: this.data,
          series: [
            ...uniqueChannels.map((c) => {
              return {
                type: 'bar',
                xKey: 'month',
                yKey: 'channels["' + c + '"]',
                yName: c,
                stacked: true,
                tooltip: {
                  renderer: function ({ datum, xKey, yKey }) {
                    return {
                      content: datum[yKey],
                      title: `${c} Count`
                    }
                  }
                }
              }
            })
          ],
          legend: {
            enabled: true,
            item: {
              label: {
                formatter: (params) => {
                  let total = this.data.reduce((acc, obj) => {
                    let value = obj['channels'][params.value] ? Number(obj['channels'][params.value]) : 0
                    return isNaN(value) ? acc : acc + value
                  }, 0)
                  this.totalLeadsCount += total
                  return params.value + ` (${total})`
                }
              }
            }
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.monthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left'
            }
          ]
        }
      }
    },
    getCharts(request) {
      this.data = []
      const timeout = setTimeout(async () => {
        this.data = await MtApi.agGridContactsCharts(request)
        clearTimeout(timeout)
      }, 1)
    },
    init(request) {
      if (request) {
        this.getCharts(request)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  .wrapper {
    display: flex;
    .total {
      width: 60%;
      .leads-count {
        font-size: 20px;
        font-weight: 600;
        padding-top: 10px;
      }
    }
  }
}
</style>

<template>
  <div class="user-activities">
    <div @click="onExpand">
      <b-icon color="blue" v-if="rotate" icon="box-arrow-in-up"></b-icon>
      <b-icon color="blue" v-else icon="box-arrow-in-down"></b-icon>
    </div>
    <span class="ml-1">({{ params.value }})</span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      rotate: false
    }
  },
  methods: {
    onExpand() {
      this.rotate = !this.rotate
      this.params.node.setExpanded(!this.params.node.expanded)
    }
  }
}
</script>

<style lang="scss" scoped>
.user-activities {
  display: flex;
  align-items: center;
}
</style>

<template>
  <div class="lead-center-management">
    <button id="charts-show-button" v-on:click="charts" :class="['button-outline-blue', 'item', showCharts ? 'router-link-active' : '']"><b-icon icon="bar-chart"></b-icon></button>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'header-lead-center-contacts-management',
  data() {
    return {
      gridApi: null
    }
  },
  computed: {
    showCharts: {
      get() {
        return this.getShowChartsLeadCenter()
      },
      set(value) {
        this.setShowChartsLeadCenter(value)
      }
    }
  },
  methods: {
    ...mapMutations([
      'setShowChartsLeadCenter'
    ]),
    ...mapGetters([
      'getShowChartsLeadCenter'
    ]),
    charts() {
      this.showCharts = !this.showCharts
    }
  }
}
</script>

<style lang="scss" scoped>
.lead-center-management {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .item {
    margin-left: 0;
    min-width: 50px;
    display: flex;
    justify-content: center;
  }
}
</style>

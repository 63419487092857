<template>
  <div>
    <date-range v-if="initDateRange && initDateRange.start"
      :selected-date-from="startDate"
      :selected-date-to="endDate"
      :periodFrom="makePeriod(false)"
      :periodTo="makePeriod(true)"
      @changed-dates="changedDates"
    ></date-range>
  </div>
</template>

<script>
import DateRange from '@/components/DateRange.vue'
import { mapGetters, mapMutations } from 'vuex'
import dayjs from 'dayjs'
import { monthYear, yearMonthDay } from '@/helpers/dateFormats'

export default {
  name: 'header-date-picker',
  components: { DateRange },
  data() {
    return {}
  },
  props: ['type'],
  computed: {
    startDate: {
      get() {
        return this.getLeadCenterDate().start
      },
      set(value) {
        this.setLeadCenterDateStart(value)
        this.$emitter.emit('header_addons_changed')
      }
    },
    endDate: {
      get() {
        return this.getLeadCenterDate().end
      },
      set(value) {
        this.setLeadCenterDateEnd(value)
        this.$emitter.emit('header_addons_changed')
      }
    },
    initDateRange() {
      return this.getInitDateRange()
    }
  },
  methods: {
    ...mapMutations([
      'setLeadCenterDateStart',
      'setLeadCenterDateEnd',
    ]),
    ...mapGetters([
      'getLeadCenterDate',
      'getSelectedPlatform',
      'getInitDateRange'
    ]),
    changedDates(obj) {
      this.setLeadCenterDateStart(obj.dateFrom)
      this.setLeadCenterDateEnd(obj.dateTo)
      this.$emitter.emit('header_addons_changed')
    },
    makePeriod(end = false) {
      const from = dayjs(this.initDateRange.start)
      const to = dayjs(this.initDateRange.end)
      let currentDate = from
      const period = []
      while (currentDate.isBefore(to) || (end ? currentDate.isSame(to, 'month') : false)) {
        let dateOption
        if (end) {
          const lastDayOfMonth = currentDate.endOf('month')
          dateOption = {
            label: lastDayOfMonth.format(monthYear),
            code: lastDayOfMonth.format(yearMonthDay)
          }
        } else {
          dateOption = {
            label: currentDate.format(monthYear),
            code: currentDate.format(yearMonthDay)
          }
        }
        period.push(dateOption)
        currentDate = currentDate.add(1, 'month')
      }
      period.sort((a, b) => dayjs(b.code).isAfter(dayjs(a.code)) ? 1 : -1);
      return period
    }
  }
}
</script>
